import React, { createContext, useState, useEffect } from 'react'
import fetch from 'isomorphic-fetch'
import Client from 'shopify-buy'

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  isMenuOpen: false,
  loading: false,
  addDiscount: () => {},
  addVariantToCart: () => {},
  removeDiscount: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = createContext(defaultValues)

const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(defaultValues.isMenuOpen)

  const setCheckoutItem = checkout => {
    localStorage.setItem(localStorageKey, checkout.id)

    setCheckout(checkout)
  }

  useEffect(() => {
    setLoading(true)
    const initializeCheckout = async () => {
      const existingCheckoutID = localStorage.getItem(localStorageKey) || null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          // Get checkout with existing ID
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          // If checkout hasn't been completed
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            setLoading(false)
            return
          }
        } catch (e) {
          // Empty localstorage checkout id if error
          localStorage.setItem(localStorageKey, null)
        }
      }

      // create new Checkout
      try {
        const newCheckout = await client.checkout.create()
        setCheckoutItem(newCheckout)
      } catch (e) {
        console.error(e)
      }
      setLoading(false)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        if (res.errors) {
          console.error(res.errors)
          setLoading(false)
          return
        }
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        if (res.errors) {
          console.error(res.errors)
          setLoading(false)
          return
        }
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        if (res.errors) {
          console.error(res.errors)
          setLoading(false)
          return
        }
        setCheckout(res)
        setLoading(false)
      })
  }

  const addDiscount = (checkoutID, discountCode) => {
    setLoading(true)
    // Add a discount code to the checkout
    return client.checkout.addDiscount(checkoutID, discountCode).then(res => {
      if (res.errors) {
        console.error(res.errors)
        setLoading(false)
        return
      }
      setCheckout(res)
      setLoading(false)
    })
  }

  const removeDiscount = (checkoutID, discountCode) => {
    setLoading(true)

    // Add a discount code to the checkout
    return client.checkout
      .removeDiscount(checkoutID, discountCode)
      .then(res => {
        if (res.errors) {
          console.error(res.errors)
          setLoading(false)
          return
        }
        setCheckout(res)
        setLoading(false)
      })
  }

  const toogleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addDiscount,
        addVariantToCart,
        removeDiscount,
        removeLineItem,
        toogleIsMenuOpen,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        isMenuOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
