/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from 'react'
import { StoreProvider } from './src/context/StoreContext'
import 'typeface-montserrat'
import '@fontsource/averia-libre/700.css'
import '@fontsource/averia-libre/300.css'

import 'normalize.css/normalize.css'
import './src/css/style.global.scss'

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)
