exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-templates-about-about-js": () => import("./../../../src/templates/About/About.js" /* webpackChunkName: "component---src-templates-about-about-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/Contact/Contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-content-page-content-page-js": () => import("./../../../src/templates/ContentPage/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-content-page-js" */),
  "component---src-templates-home-page-home-page-js": () => import("./../../../src/templates/HomePage/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-home-page-js" */),
  "component---src-templates-product-page-product-page-js": () => import("./../../../src/templates/ProductPage/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-product-page-js" */),
  "component---src-templates-shop-page-shop-page-js": () => import("./../../../src/templates/ShopPage/ShopPage.js" /* webpackChunkName: "component---src-templates-shop-page-shop-page-js" */)
}

